import { awaitNextFrame } from './functions';
import onWidthResize, { stuttering } from './onWidthResize';

const CLASS_INITIALIZED = 'initialized';

const setMainMarginTop = async () => {
	const nav = document.querySelector('.nav-layout');
	const pageMain = document.querySelector('.page-main');
	const marketRedirect = document.querySelector('.market-redirect-widget');

	let yOffset = nav.offsetHeight;
	if (marketRedirect) {
		yOffset += marketRedirect.offsetHeight;
	}
	const root = document.documentElement;
	root.style.setProperty('--page-main-margin-top-nav', `${yOffset}px`);
	await awaitNextFrame();
	pageMain.classList.add(CLASS_INITIALIZED);
};

const initMainMarginTop = () => {
	setMainMarginTop();
	onWidthResize(setMainMarginTop, stuttering.DEBOUNCE, 50);
	document.addEventListener('DOMContentLoaded', setMainMarginTop);
};

export { setMainMarginTop, initMainMarginTop };
